import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMobileAlt,
  faLaptop,
  faTools,
  faVideo,
  /* faArrowUp, */
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

import React, { useState } from "react";

import { Link } from "react-router-dom";
/* import { useHistory } from "react-router-dom"; */

import {
  Container,
  Row,
  Col,
  Button,
  NavDropdown,
  Nav,
  Navbar,
} from "react-bootstrap";

import classnames from "classnames";

import logo from "../Img/Logo.png";
import whiteLogo from "../Img/whiteLogo.png";

export default function NavbarSec({ message }) {
  const [show, setShow] = useState(false);
  const [secshow, setsecShow] = useState(false);
  const [thirdshow, setthirdShow] = useState(false);
  /* const history = useHistory(); */
  const showDropdown = (e) => {
    setShow(!show);
    setsecShow(false);
    setthirdShow(false);
  };

  const showthirdDropdown = (e) => {
    setthirdShow(!thirdshow);
    setsecShow(false);
    setShow(false);
  };

  const hidedropdown = (e) => {
    setShow(false);
    setsecShow(false);
    setthirdShow(false);
  };

  /* const redirectUpgrade = () => {
    history.push("/upgrade");
  }; */

  const openExternalURL = () => {
    window.open("https://www.youtube.com/channel/UCYhukSNdB3GZb-XIoiB6qyg");
  };
  return (
    <>
      <div className="desktopNavbar">
        <Navbar
          bg="light"
          expand="lg"
          onMouseLeave={hidedropdown}
          className={classnames({
            "fixed-with-server-status": message !== null,
          })}
        >
          <Container>
            <Navbar.Brand href="/#home">
              <h3>
                <span>
                  {" "}
                  <img className="logo" src={logo} alt="logo" />
                </span>
                &nbsp;&nbsp;<span className="elite">Elite</span>
                <b>Cloud</b>
              </h3>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="text-center">
                <Nav.Link href="/">Home</Nav.Link>
                <NavDropdown
                  title="Product"
                  id="basic-nav-dropdown"
                  show={show}
                  onMouseEnter={showDropdown}
                  onMouseLeave={hidedropdown}
                  onClick={() => setShow(!show)}
                >
                  {show ? (
                    <Container>
                      <Row>
                        <Col xs={2} className="headerImg">
                          <div className="layer">
                            <img src={whiteLogo} alt="logo" />
                          </div>
                        </Col>
                        <Col className="dropdown-col">
                          <NavDropdown.Item href="/#smartApp">
                            <Container>
                              <Row>
                                <Col xs={2}>
                                  <Button
                                    className="btn-drop fontBtn"
                                    size="sm"
                                  >
                                    <FontAwesomeIcon icon={faMobileAlt} />
                                  </Button>
                                </Col>
                                <Col xs={{ span: 2, offset: 2 }}>
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  <span>Smart Phone App</span>
                                  <div>
                                    Intuitive security & control
                                    <br /> of your home or business
                                    <br /> via almost any smart device.
                                    <br />
                                    <br />
                                  </div>
                                </Col>
                              </Row>
                            </Container>
                          </NavDropdown.Item>
                          <NavDropdown.Item style={{ cursor: "no-drop" }}>
                            <Container>
                              <Row>
                                <Col xs={2}>
                                  <Button
                                    className="btn-drop fontBtn"
                                    size="sm"
                                  >
                                    <FontAwesomeIcon icon={faLaptop} />
                                  </Button>
                                </Col>
                                <Col xs={{ span: 2, offset: 2 }}>
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  <span>User Dashboard</span>
                                  <div>
                                    User management <br />
                                    tools for PC or MAC.
                                    <br />
                                    <p className="colr">Coming Soon</p>
                                  </div>
                                </Col>
                              </Row>
                            </Container>
                          </NavDropdown.Item>
                        </Col>
                        <Col>
                          <NavDropdown.Item href="/#dashboard">
                            <Container>
                              <Row>
                                <Col xs={2}>
                                  <Button
                                    className="btn-drop fontBtn"
                                    size="sm"
                                  >
                                    <FontAwesomeIcon icon={faTools} />
                                  </Button>
                                </Col>
                                <Col xs={{ span: 2, offset: 2 }}>
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  <span>Installer Dashboard</span>
                                  <div>
                                    Cloud programming
                                    <br />& analysis tool.
                                  </div>
                                </Col>
                              </Row>
                            </Container>
                          </NavDropdown.Item>
                          <NavDropdown.Item style={{ cursor: "no-drop" }}>
                            <Container>
                              {/* <Row>
                                <Col xs={2}>
                                  <Button
                                    className="btn-drop fontBtn"
                                    size="sm"
                                  >
                                    <FontAwesomeIcon icon={faLink} />
                                  </Button>
                                </Col>
                                <Col xs={{ span: 2, offset: 2 }}>
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  <span>Developer API</span>
                                  <div>
                                    Integrate your service
                                    <br />
                                    with EliteCloud.
                                    <br />
                                    <p className="colr">Coming Soon</p>
                                  </div>
                                </Col>
                              </Row> */}
                            </Container>
                          </NavDropdown.Item>
                        </Col>
                      </Row>
                    </Container>
                  ) : null}
                </NavDropdown>
                {/* <Nav.Link href="/#pricing">Pricing</Nav.Link> */}
                {/* <NavDropdown
                  title="Pricing"
                  id="basic-nav-dropdown"
                  show={secshow}
                  onMouseEnter={showsecDropdown}
                  onMouseLeave={hidedropdown}
                  onClick={() => setsecShow(!secshow)}
                >
                  {secshow ? (
                    <Container>
                      <Row>
                        <Col xs={2} className="headerImg">
                          <div className="layerPric">
                            <img src={whiteLogo} alt="whiteLogo" />
                          </div>
                        </Col>

                        <Col>
                          <NavDropdown.Item href="/#smartApp">
                            <Container>
                              <Row>
                                <Col xs={2}>
                                  <Button
                                    className="btn-drop fontBtn"
                                    size="sm"
                                  >
                                    <FontAwesomeIcon icon={faMobileAlt} />
                                  </Button>
                                </Col>
                                <Col
                                  xs={{ span: 3, offset: 2 }}
                                  md={{ span: 2, offset: 2 }}
                                >
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  <span>In Your Hand</span>
                                  <div>
                                    Intuitive security & control
                                    <br /> of your home or business
                                    <br /> via almost any smart device
                                    <br />
                                    <br />
                                    <div>
                                      <br />
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </Container>
                          </NavDropdown.Item>
                        </Col>
                        <Col>
                          <NavDropdown.Item href="/#dashboard">
                            <Container>
                              <Row>
                                <Col xs={2}>
                                  <Button
                                    className="btn-drop fontBtn"
                                    size="sm"
                                  >
                                    <FontAwesomeIcon icon={faLaptop} />
                                  </Button>
                                </Col>
                                <Col xs={{ span: 2, offset: 2 }}>
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  <span>At Your Desk</span>
                                  <div>
                                    User management tool
                                    <br /> for PC or MAC
                                    <br />
                                    <p className="colr">Coming Soon</p>
                                    <div>
                                      <br />
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </Container>
                          </NavDropdown.Item>
                        </Col>
                      </Row>
                    </Container>
                  ) : null}
                </NavDropdown> */}
                <NavDropdown
                  title="More"
                  id="collasible-nav-dropdown"
                  show={thirdshow}
                  onMouseEnter={showthirdDropdown}
                  onMouseLeave={hidedropdown}
                  onClick={() => setthirdShow(!thirdshow)}
                >
                  {thirdshow ? (
                    <Container>
                      <Row>
                        <Col xs={2} className="headerImg">
                          <div className="layer">
                            <img src={whiteLogo} alt="logo" />
                          </div>
                        </Col>
                        <Col xs={8} className="dropdown-col">
                          <NavDropdown.Item>
                            <Container>
                              <Row>
                                <Col xs={2}>
                                  <Button
                                    className="btn-drop fontBtn"
                                    size="sm"
                                  >
                                    <FontAwesomeIcon icon={faVideo} />
                                  </Button>
                                </Col>
                                <Col
                                  xs={{ span: 6, offset: 3 }}
                                  onClick={openExternalURL}
                                >
                                  &nbsp;&nbsp;&nbsp;&nbsp;<span>Videos</span>
                                  <p>
                                    Intuitive security & control <br />
                                    of your home or business <br />
                                    via almost any smart device.
                                  </p>
                                  <br />
                                </Col>
                              </Row>
                            </Container>
                          </NavDropdown.Item>
                          {/* <NavDropdown.Item>
                            <Container>
                              <Row>
                                <Col xs={2}>
                                  <Button
                                    className="btn-drop fontBtn"
                                    size="sm"
                                  >
                                    <FontAwesomeIcon icon={faArrowUp} />
                                  </Button>
                                </Col>
                                <Col
                                  xs={{ span: 6, offset: 3 }}
                                  onClick={redirectUpgrade}
                                >
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  <span>Upgrade to EliteCloud</span>
                                  <p>
                                    Step by step guide on how <br />
                                    to upgrade your old <br /> EliteControl APP
                                    to EliteCloud.
                                  </p>
                                  <br />
                                </Col>
                              </Row>
                            </Container>
                          </NavDropdown.Item> */}
                          <NavDropdown.Item href="mailto: support@aap.co.nz">
                            <Container>
                              <Row>
                                <Col xs={2}>
                                  <Button
                                    className="btn-drop fontBtn"
                                    size="sm"
                                  >
                                    <FontAwesomeIcon icon={faPhone} />
                                  </Button>
                                </Col>
                                <Col xs={{ span: 6, offset: 3 }}>
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  <span>Contact us</span>
                                  <div>
                                    You can get in touch with <br />
                                    our support team at <br />
                                    <p className="mailLink">
                                      {" "}
                                      support@aap.co.nz .
                                    </p>{" "}
                                  </div>
                                  <br />
                                </Col>
                              </Row>
                            </Container>
                          </NavDropdown.Item>
                        </Col>

                        <Col></Col>
                      </Row>
                    </Container>
                  ) : null}
                </NavDropdown>
              </Nav>
              <Navbar.Collapse className="justify-content-end">
                <div className="navSgn">
                  <Link
                    to={{
                      pathname: "https://dashboard.elitecloud.co.nz/account/",
                    }}
                    target="_blank"
                  >
                    <Button variant="outline-primary">Sign In</Button>
                    &nbsp;&nbsp;
                  </Link>
                  <Link
                    to={{
                      pathname:
                        "https://dashboard.elitecloud.co.nz/account/register/",
                    }}
                    target="_blank"
                  >
                    <Button className="btnSignup" variant="outline-primary">
                      Sign Up
                    </Button>
                  </Link>
                </div>
              </Navbar.Collapse>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <div className="mobl">
        <Navbar
          bg="dark"
          expand="lg"
          fixed="top"
          className={classnames({
            "fixed-with-server-status": message !== null,
          })}
        >
          <Container fluid>
            <Navbar.Brand href="/#home">
              <h3>
                <span>
                  <img className="logo" src={logo} alt="logo" />
                </span>
                &nbsp;&nbsp;<span className="elite">Elite</span>
                <b>Cloud</b>
              </h3>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse className="mainCollapse" id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link href="/">Home</Nav.Link>

                <NavDropdown
                  title="Product"
                  id="basic-nav-dropdown"
                  onClick={() => setShow(!show)}
                >
                  {show ? (
                    <>
                      <NavDropdown.Item href="/#smartApp">
                        Smart Phone App
                      </NavDropdown.Item>
                      <NavDropdown.Item style={{ cursor: "no-drop" }}>
                        User Dashboard
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/#dashboard">
                        Installer Dashboard
                      </NavDropdown.Item>
                      {/* <NavDropdown.Item style={{ cursor: "no-drop" }}>
                        Developer API
                      </NavDropdown.Item> */}
                    </>
                  ) : null}
                </NavDropdown>

                <NavDropdown
                  title="Pricing"
                  id="basic-nav-dropdown"
                  onClick={() => setsecShow(!secshow)}
                >
                  {secshow ? (
                    <>
                      <NavDropdown.Item href="/#smartApp">
                        In Your Hand
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/#dashboard">
                        At Your Desk
                      </NavDropdown.Item>
                    </>
                  ) : null}
                </NavDropdown>

                <NavDropdown
                  title="More"
                  id="basic-nav-dropdown"
                  onClick={() => setthirdShow(!thirdshow)}
                >
                  {thirdshow ? (
                    <>
                      {/* <NavDropdown.Item href="#action/3.1">
                        Documentation
                      </NavDropdown.Item>
                      <NavDropdown.Item href="#action/3.2">
                        Blog
                      </NavDropdown.Item> */}
                      <NavDropdown.Item
                        href="#action/3.3"
                        onClick={openExternalURL}
                      >
                        Videos
                      </NavDropdown.Item>
                      {/* <NavDropdown.Item
                        href="#action/3.4"
                        onClick={redirectUpgrade}
                      >
                        Upgrade to EliteCloud
                      </NavDropdown.Item> */}
                      <NavDropdown.Item href="mailto: support@aap.co.nz">
                        Contact Us
                      </NavDropdown.Item>
                    </>
                  ) : null}
                </NavDropdown>
              </Nav>
              <Navbar.Collapse className="justify-content-end">
                <div className="navSgn">
                  <Link
                    to={{
                      pathname: "https://dashboard.elitecloud.co.nz/account/",
                    }}
                    target="_blank"
                  >
                    <Button variant="outline-primary">Sign In</Button>
                    &nbsp;&nbsp;
                  </Link>
                  <Link
                    to={{
                      pathname:
                        "https://dashboard.elitecloud.co.nz/account/register/",
                    }}
                    target="_blank"
                  >
                    <Button className="btnSignup" variant="outline-primary">
                      Sign Up
                    </Button>
                  </Link>
                </div>
                <br />
              </Navbar.Collapse>
            </Navbar.Collapse>
          </Container>
          <br />
        </Navbar>
      </div>
    </>
  );
}
