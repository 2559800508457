import React, { useState } from "react";

import { Container, Row, Col } from "react-bootstrap";

import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap";

import HistoryDesk from "../Img/HistoryDesk.png";
import Programming from "../Img/Programming.png";
import Sites from "../Img/Sites.png";
import Status from "../Img/Status & Control.png";
import Update from "../Img/Update.png";
import cloudIcon from "../Img/cloudIcon.png";
import slider from "../Img/slider.png";

const items = [
  {
    id: 1,
    caption: "Sites",
    Text: `Manage unlimited sites from the cloud.\n\nAny EliteControl security system can be added to your installer dashboard for remote configuration, notifications, updates & backup, whether you are at home, work or on vacation.`,
  },
  {
    id: 2,
    caption: "Status & Control",
    Text: "Arm, disarm & open doors, garages & gates directly from your PC or MAC.\n\nIdeal for business sites or anywhere that requires a secure user desktop management & control platform.",
  },
  {
    id: 3,
    caption: "History",
    Text: "More than 10,000 history events.\n\nHistory from every EliteControl panel connected to the cloud is logged & made available for instant analysis. Each data log includes the system operation & the associated time & date to accurately pinpoint exactly what you are looking for.",
  },
  {
    id: 4,
    caption: "Cloud Programming",
    Text: "Secure configuration from almost anywhere.\n\nInstaller programming for MAC or PC from the cloud. Throw away your clunky programming keypad & address type manuals & replace them with our simple and intuitive installer focused dashboard.",
  },
  {
    id: 5,
    caption: "Cloud Updates",
    Text: "New features, no problem.\n\nUpdating your hardware remotely means no more costly site visits or awkward customer conversations. A service overview can now take place in your lounge rather than sitting in the worlds ever increasing traffic.",
  },
];
const ImgSlides = [
  {
    id: 1,
    img: Sites,
  },
  {
    id: 2,
    img: Status,
  },
  {
    id: 3,
    img: HistoryDesk,
  },
  {
    id: 4,
    img: Programming,
  },
  {
    id: 5,
    img: Update,
  },
  {
    id: 6,
    img: slider,
    altText: "ImgSlide 6",
    caption: "ImgSlide 6",
  },
];

const DashboardSlider = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        className="carousel"
        tag="div"
        key={item.id}
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
      >
        <CarouselCaption
          captionHeader={item.caption}
          captionText={item.Text}
          captionParagraph={item.content}
        />
      </CarouselItem>
    );
  });
  const Imgslides = ImgSlides.map((ImgSlide) => {
    return (
      <CarouselItem
        className="carousel"
        tag="div"
        key={ImgSlide.id}
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
      >
        <img
          src={ImgSlide.img}
          data-aos="fade-up-right"
          data-aos-once="true"
          alt="imgslider"
        />
      </CarouselItem>
    );
  });

  return (
    <div>
      <Container>
        <Row className="dashboardPricng">
          <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
            <div className="appCarousel">
              <h1>
                <img src={cloudIcon} width="50px" alt="icon" />
                &nbsp;&nbsp;At Your Desk
              </h1>{" "}
            </div>
            <Carousel
              activeIndex={activeIndex}
              next={next}
              slide={true}
              previous={previous}
              interval="20000"
              className="carousel slide"
            >
              {slides}
              <Col md={{ span: 2, offset: 3 }}>
                <CarouselControl
                  direction="prev"
                  directionText="Previous"
                  onClickHandler={previous}
                />
                <CarouselControl
                  direction="next"
                  directionText="Next"
                  onClickHandler={next}
                />
                <CarouselIndicators
                  items={items}
                  activeIndex={activeIndex}
                  onClickHandler={goToIndex}
                />
              </Col>
            </Carousel>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
            <Carousel
              activeIndex={activeIndex}
              next={next}
              previous={previous}
              pause={false}
              ride="carousel"
              interval="20000"
              className="carousel-fade faimg"
            >
              {Imgslides}
            </Carousel>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DashboardSlider;
